import http from './http.service';

class OrderService {
    constructor() {
        if(OrderService.instance) {
            return OrderService.instance;
        }

        OrderService.instance = this;
    }

    adverts() {
        return http.get('/api/order/adverts').then(res => res.data);
    }

    availableMonths() {
        return http.get('/api/order/available-months').then(res => res.data);
    }

    checkDiscount(params) {
        return http.get('/api/order/check-discount', {params: params}).then(res => res.data);
    }

    availableDiscounts() {
        return http.get('/api/order/discounts').then(res => res.data);
    }

    store(data) {
        return http.post('/api/order/store', data).then(res => res.data);
    }
}

export default new OrderService();
