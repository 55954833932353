<template>
    <div class="container-fluid" v-if="form && form.company">
        <Header :name="`Tellimus nr: ${form.id} muutmine`"/>
        <div class="card mt-3">
            <div class="card-body">
                <h5 class="card-title">
                    <router-link :to="{name:'companies.show', params:{company_id:form.company_id}}">
                        {{ form.company.name }}
                    </router-link>
                    <button type="button" class="btn btn-success float-right"
                            @click="update">
                        Salvesta
                    </button>
                </h5>
                <h6 class="card-subtitle mb-2 text-muted">Tellitud: {{ form.created_at | formatDate }}</h6>
                <hr>
                <p>Lisainfo</p>
                <p>{{ form.info || '-' }}</p>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pind</th>
                        <th>Hind €</th>
                        <th>Kuu(d)</th>
                        <th>Allahindlus %</th>
                        <th>Failid/info</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, rowIndex) in form.rows">
                        <td>{{ rowIndex + 1 }}</td>
                        <td>{{ row.advert.name }}</td>
                        <td>
                            <small v-if="row.discount">(-{{ row.discount }}%)</small>
                            {{ calcRowPrice(row) }}
                        </td>
                        <td>
                            <div v-for="month in availableMonths">
                                <input type="checkbox"
                                       :class="{ 'is-invalid': $v.form.rows.$each[rowIndex].months.$error }"
                                       :id="`month_${month.value}_${rowIndex}`"
                                       :checked="row.months.map(m => m.month).includes(month.date)"
                                       @click="addMonth(month.date, row)"/>
                                <label class="mb-0" :for="`month_${month.value}_${rowIndex}`">
                                    {{ month.date | formatMonthAndYear }}
                                </label>
                            </div>
                        </td>
                        <td><input type="text" class="form-control" v-model="row.discount" @keyup="calcTotalPrice"></td>
                        <td>
                            <template v-for="(file, fileIndex) in row.files">
                                <a href="#" @click="donwloadFile(file)">{{ file.filename }}</a><br>
                            </template>
                            <span v-if="row.info">
                                <textarea class="form-control" v-model="row.info"></textarea>
                            </span>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colspan="5">KOKKU</th>
                        <th>{{ total }} €</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@components/layout/Header';
import OrdersService from '@services/orders.service';
import {mapGetters} from "vuex";
import {required} from 'vuelidate/lib/validators';
import moment from "moment";

export default {
    data() {
        return {
            form: {
                rows: []
            },
            total: 0
        }
    },
    computed: {
        ...mapGetters(['availableMonths']),
    },
    methods: {
        update() {
            this.$store.dispatch('UPDATE_ORDER', this.form).then(res => {
                this.$toastr.s(res.message);
                this.$router.push({name: 'orders.show', params: {order_id: this.form.id}});
            });
        },
        calcRowPrice(row) {
            let rowTotal = 0;
            row.months.map(r => {
                rowTotal += r.price;
            });
            rowTotal = rowTotal / 100;

            return (rowTotal - ((rowTotal / 100) * row.discount)).toFixed(2)
        },
        addMonth(month, row) {
            if (!row.months.find(mo => mo.month === month)) {
                if (moment(month).isSameOrAfter(row.advert.new_price_at)) {
                    row.months.push({month: month, price: row.advert.new_price});
                } else {
                    row.months.push({month: month, price: row.advert.price});
                }
            } else {
                const index = row.months.findIndex(mo => mo.month === month);
                row.months.splice(index, 1);
            }

            this.calcTotalPrice();
        },
        calcTotalPrice() {
            let total = 0;
            this.form.rows.map(row => {
                let rowTotal = 0;
                row.months.map(r => {
                    rowTotal += r.price;
                });
                let rowPrice = (rowTotal) - ((rowTotal / 100) * row.discount);
                total = total + rowPrice;
            });
            this.total = total / 100;
        },
        getOrder() {
            const order = this.$store.getters.currentOrderById(this.$route.params.order_id);
            if (!order) {
                OrdersService.show(this.$route.params.order_id).then(res => this.setRows(res));
            } else {
                this.setRows(order);
            }
        },
        setRows(order) {
            let rows = [];
            order.rows.map(row => {
                let price = 0;
                row.months.map(m => {
                    price += m.price;
                });
                rows.push({
                    id: row.id,
                    advert: row.advert,
                    files: row.files,
                    order_id: row.order_id,
                    ad_id: row.ad_id,
                    months: row.months,
                    discount: row.discount,
                    price: price / 100,
                    info: row.info
                });
            });
            this.form = {
                id: order.id,
                company: order.company,
                archived_at: order.archived_at,
                company_id: order.company_id,
                rows
            };
            this.calcTotalPrice();
        },
        donwloadFile(file) {
            OrdersService.download(file.id, file.filename, file.mime);
        },
        getAvailableMonths() {
            this.$store.dispatch('GET_AVAILABLE_MONTHS');
        }
    },
    components: {
        Header
    },
    created() {
        this.getOrder();
        this.getAvailableMonths();
    },
    validations: {
        form: {
            rows: {
                $each: {
                    months: {
                        required
                    },
                    ad_id: {
                        required
                    }
                }
            }
        }
    }
};
</script>

