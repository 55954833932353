<template>
    <modal name="edit-settings-modal" height="auto" :scrollable="true" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h3 class="modal-title">Muuda</h3>
                <i class="fa fa-times" @click="closeModal()"></i>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Tellimiue automaatse lukustamise kuupäev*</label>
                    <select class="form-control"
                            :class="{ 'is-invalid': $v.form.value.$error }"
                            v-model="$v.form.value.$model"
                            @change="chooseDate($event)">
                        <option :value="date" v-for="date in 31">
                            {{ date }}
                        </option>
                    </select>
                </div>
                <div class="form-group text-center">
                    <button @click="update" class="btn btn-success">
                        Salvesta
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {required} from 'vuelidate/lib/validators';
    import SettingsService from '@services/settings.service';

    export default {
        name: 'EditSettingsModal',
        data() {
            return {
                id: null,
                form: {},
            };
        },
        methods: {
            beforeOpen(event) {
                this.id = event.params.setting.id;
                this.form = Object.assign({}, event.params.setting);
            },
            chooseDate(event) {
                this.form.value = event.target.value;
            },
            beforeClose() {
                this.$v.$reset();
            },
            closeModal() {
                this.$modal.hide('edit-settings-modal');
            },
            update() {
                this.$v.$touch();
                if(!this.$v.$invalid) {
                    SettingsService.update(this.id, this.form).then(res => {
                        this.$emit('updateSettings', res);
                        this.$toastr.s('Savestatud');
                        this.form = {};
                        this.id = null;
                        this.closeModal();
                    })
                }
            }
        },
        validations: {
            form: {
                value: {
                    required
                },
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v--modal-overlay {
        z-index: 1000;
    }

    .modal-header {
        .fa-times {
            cursor: pointer;
        }
    }
</style>
