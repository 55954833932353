import Vue from 'vue';
import VueRouter from 'vue-router';
import {filters} from './filters';
import router from './routes';
import App from './App.vue';
import store from './store';
import Toastr from 'vue-toastr';
import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import VueBase64FileUpload from 'vue-base64-file-upload';

require('./bootstrap');

Vue.component('Uploader', VueBase64FileUpload);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(VModal);
Vue.use(Toastr, {
    defaultTimeout: 3000,
    defaultPosition: 'toast-top-center',
    defaultCloseOnHover: false,
    defaultPreventDuplicates: true,
    defaultProgressBar: false
});

export const vm = new Vue({
    el: '#app',
    router,
    filters,
    store,
    render: h => h(App)
});
