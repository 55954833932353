<template>
    <div class="container-fluid">
        <Header name="Reklaamid" addNew @create="create"/>
        <table class="table table-hover bg-white mt-3">
            <thead>
            <tr>
                <th>#</th>
                <th>Nimi</th>
                <th>Suurus</th>
                <th>Hind €/kuu</th>
                <th>Uus hind €/kuu</th>
                <th>Uue hinna algus</th>
                <th>Failid lubatud</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(advert, i) in adverts" :key="advert.id">
                <td>{{ i + 1}}</td>
                <td>{{ advert.name }}</td>
                <td>{{ advert.size }}</td>
                <td>{{ advert.price / 100 }}</td>
                <td>{{ advert.new_price ? advert.new_price / 100 : '' }}</td>
                <td>{{ advert.new_price_at | formatDate }}</td>
                <td>{{ advert.files_allowed ? 'Jah' : 'Ei' }}</td>
                <td>
                    <div class="pull-right">
                        <button class="btn btn-outline-info btn-sm" @click="showPreview(advert)" v-if="advert.size !== '0'">
                            Eelvaade
                        </button>
                        <button type="button" class="btn btn-outline-primary btn-sm" @click="edit(advert)">Muuda</button>
                        <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteAdvert(advert.id)">Kustuta</button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <CreateModal />
        <EditModal />
        <AdSizePreviewModal/>
    </div>
</template>

<script>
    import Header from '@components/layout/Header';
    import CreateModal from './modals/CreateAdvertModal';
    import EditModal from './modals/EditAdvertModal';
    import AdvertService from '@services/advert.service';
    import AdSizePreviewModal from '../AdSizePreviewModal';
    import {mapGetters} from 'vuex';

    export default {
        computed: {
            ...mapGetters(['adverts'])
        },
        methods: {
            showPreview(row) {
                this.$modal.show('ad-size-preview-modal', {row});
            },
            deleteAdvert(advertId) {
                if (!window.confirm('Kustutame?')) {
                    return false;
                }
                AdvertService.delete(advertId).then(res => {
                    this.$store.commit('REMOVE_ADVERT', advertId);
                    this.$toastr.s(res.message);
                });
            },
            create() {
                this.$modal.show('create-advert-modal');
            },
            edit(advert) {
                this.$modal.show('edit-advert-modal', { advert });
            }
        },
        components: {
            Header,
            AdSizePreviewModal,
            CreateModal,
            EditModal
        },
        created() {
            this.$store.dispatch('INIT_ADVERTS');
        }
    };
</script>
