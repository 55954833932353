import Vue from 'vue';
import moment from 'moment';

moment.locale('et');

const formatDate = Vue.filter('formatDate', (date) => {
    if (date !== null) {
        return moment(date).format('L');
    }
    return null;
});

const formatDateInput = Vue.filter('formatDateInput', (date) => {
    if (date !== null) {
        return moment(date).format('YYYY-MM-DD');
    }
    return null;
});

const formatFullMonthDate = Vue.filter('formatFullMonthDate', (date) => {
    if (date !== null) {
        return moment(date).format('LL');
    }
    return null;
});

const formatDateTime = Vue.filter('formatDateTime', (date) => {
    if (date !== null) {
        return moment(date).format('L LT');
    }
    return null;
});

const formatTime = Vue.filter('formatTime', (date) => {
    if (date !== null) {
        return moment(date).format('LT');
    }
    return null;
});

const formatDayString = Vue.filter('formatDayString', (date) => {
    if (date !== null) {
        return moment(date).format('dddd').charAt(0).toUpperCase() + moment(date).format('dddd').slice(1);
    }
    return null;
});

const formatDayInt = Vue.filter('formatDayInt', (date) => {
    if (date !== null) {
        return moment(date).format('D');
    }
    return null;
});

const formatMonth = Vue.filter('formatMonth', (date) => {
    if (date !== null) {
        return moment(date).format('MMMM');
    }
    return null;
});

const formatMonthAndYear = Vue.filter('formatMonthAndYear', (date) => {
    if (date !== null) {
        return moment(date, 'DD.MM.YYYY').format('MMMM YYYY');
    }
    return null;
});

const formatMonthAndYearShort = Vue.filter('formatMonthAndYearShort', (date) => {
    if (date !== null) {
        return moment(date, 'DD.MM.YYYY').format('MMM YYYY');
    }
    return null;
});

const truncate = Vue.filter('truncate', (value, length) => {
    length = length || 15;
    if (!value || typeof value !== 'string') {
        return '';
    }
    if (value.length <= length) {
        return value;
    }
    return value.substring(0, length) + '...';
});

const round = Vue.filter('round', (value, decimals) => {
    if (value > 0 && value !== null) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }
    return 0;
});

export const filters = {
    formatDate,
    formatDateInput,
    formatFullMonthDate,
    formatDateTime,
    formatTime,
    formatDayString,
    formatDayInt,
    formatMonth,
    formatMonthAndYearShort,
    formatMonthAndYear,
    truncate,
    round
};
