<template>
    <div class="container-fluid">
        <Header name="Tellimused"/>
        <div class="card">
            <div class="card-body">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <router-link to="/auth/orders" class="nav-link" tag="a" exact>
                            Kinnitamata
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/auth/orders/approved" class="nav-link" tag="a" exact>
                            Kinnitatud
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/auth/orders/paid" class="nav-link" tag="a" exact>
                            Makstud
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/auth/orders/archived" class="nav-link" tag="a" exact>
                            Arhiiv
                        </router-link>
                    </li>
                </ul>
                <div class="tab-content">
                    <router-view name="orders"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from '@components/layout/Header';

    export default {
        components: {
            Header
        }
    };
</script>
