<template>
    <div class="d-flex flex-column">
        <div class="container">
            <img src="/img/header.png" alt="" class="img-fluid mt-1">
            <div class="card mt-1 mb-5">
                <div class="card-body">
                    <h5 class="card-title">Reklaami ja kuulutuste broneerimine infolehes „Rae Sõnumid“</h5>
                    <hr>
                    <div v-html="content.footer_content_upper"></div>
                    <hr>
                    <button type="button" class="btn btn-danger" @click="addAdvert">Lisa juurde reklaami pind</button>
                    <div class="card mt-3" v-if="form.rows.length">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-3">Reklaami tüüp</div>
                                <div class="col-2 text-center">Hind €</div>
                                <div class="col text-center">Kuu(d)</div>
                                <div class="col-2">Failid/info</div>
                            </div>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item p-2" v-for="(row, rowIndex) in form.rows"
                                :key="`advert_${rowIndex}`">
                                <div class="row">
                                    <div class="col-3">
                                        <select class="form-control"
                                                :class="{ 'is-invalid': $v.form.rows.$each[rowIndex].ad_id.$error }"
                                                v-model="row.ad_id"
                                                @change="chooseAd($event, row)">
                                            <option :value="advert.id" v-for="advert in adverts">
                                                {{ advert.name }} €{{ advert.new_price / 100 }}
                                            </option>
                                        </select>
                                        <button class="btn btn-outline-primary mt-2" @click="showPreview(row)" v-if="row.size !== '0' && row.ad_id">
                                            Eelvaade <i class="fa fa-search"></i>
                                        </button>
                                        <button type="button" class="btn btn-outline-danger mt-2" @click="removeAd(rowIndex)">
                                            Kustuta
                                        </button>
                                    </div>
                                    <div class="col-2 text-center">
                                        <h5 class="mt-4">
                                            <small v-if="row.discount">(-{{ row.discount }}%)</small>
                                            {{ calcRowPrice(row.price, row.months.length, rowIndex) }}
                                        </h5>
                                    </div>
                                    <div class="col">
                                        <div v-if="form.rows[rowIndex].ad_id" class="row">
                                            <div class="col pl-1 pr-1" v-for="month in availableMonths">
                                                <label class="mb-0" :for="`month_${month.value}_${rowIndex}`"
                                                       :title="month.value !== '7' ? 'Pinnad broneeritud' : 'Ei ilmu'">
                                                    {{ month.short_name }} <br>
                                                    <small>{{ month.year }}</small>
                                                </label>
                                                <div class="custom-control custom-checkbox pt-0"
                                                     v-if="showMonthCheckbox(row, month)">
                                                    <input type="checkbox" class="custom-control-input pt-0"
                                                           :class="{ 'is-invalid': $v.form.rows.$each[rowIndex].months.$error }"
                                                           :id="`month_${month.value}_${rowIndex}`"
                                                           :checked="row.months.includes(month.date)"
                                                           @click="addMonth(month.date, row)"/>
                                                    <label class="custom-control-label"
                                                           :for="`month_${month.value}_${rowIndex}`"/>
                                                </div>
                                                <i class="fa fa-close" v-else
                                                   :title="month.value !== '7' ? 'Pinnad broneeritud' : 'Ei ilmu'"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <Uploader
                                            v-if="form.rows[rowIndex].files_allowed"
                                            class="v1"
                                            :accept="accept"
                                            input-class="form-control mt-3 btn-outline-primary"
                                            disable-preview
                                            :max-size="maxMbSize"
                                            placeholder="Vali failid"
                                            file-name="Vali failid"
                                            @size-exceeded="onSizeExceeded"
                                            @file="onFile($event, rowIndex)"
                                            @load="onLoad($event, rowIndex)"/>
                                        <ul v-if="row.files.length" style="padding-left: 17px;">
                                            <li v-for="(file, fileIndex) in row.files">
                                                {{ file.name }}
                                                <i class="fa fa-times text-danger"
                                                   @click="removeFile(rowIndex, fileIndex)"></i>
                                            </li>
                                        </ul>
                                        <div v-if="!form.rows[rowIndex].files_allowed && form.rows[rowIndex].ad_id">
                                            <textarea :id="`row_info_${rowIndex}`" class="form-control"
                                                      v-model="row.info"
                                                      :maxlength="row.max_chars"
                                                      :placeholder="`Kuni ${row.max_chars} tähemärki`">
                                            </textarea>
                                            <div>Järgi: <span v-text="(row.max_chars - row.info.length)"></span> märki
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="card-footer">
                            <div v-if="!form.discount" class="row">
                                <div class="col-md-2 col-sm-6">
                                    <h5 class="mt-2">KOKKU</h5>
                                </div>
                                <div class="col-md-2 col-sm-6 text-center">
                                    <h5 class="mt-2">{{ !form.payment ? `${total} €/kuus` : `${total} €` }}</h5>
                                </div>
                            </div>
                            <div v-else class="row">
                                <div class="col-md-2 col-sm-6">
                                    <h5 class="mt-2">Summa</h5>
                                </div>
                                <div class="col-md-2 col-sm-6 text-center">
                                    <h5 class="mt-2">{{ !form.payment ? `${total} €/kuus` : `${total} €` }}</h5>
                                </div>
                                <div class="col-md-2 col-sm-6">
                                    <h5 class="mt-2">Allahindlus</h5>
                                </div>
                                <div class="col-md-1 col-sm-6 text-center">
                                    <h5 class="mt-2">{{ form.discount }}%</h5>
                                </div>
                                <div class="col-md-2 col-sm-6">
                                    <h5 class="mt-2">KOKKU</h5>
                                </div>
                                <div class="col-md-2 col-sm-6 text-center">
                                    <h5 class="mt-2">
                                        {{!form.payment ? `${(total - total * (form.discount / 100))} €/kuus` : `${(total - total * (form.discount / 100))} €` }}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label>Ettevõtte nimi / Eraisiku ees- ja perekonnanimi *</label>
                                        <input type="text" class="form-control"
                                               :class="{ 'is-invalid': $v.form.name.$error }"
                                               v-model="$v.form.name.$model" @blur="checkDiscount">
                                    </div>
                                </div>
<!--                                <div class="col">-->
<!--                                    <div class="form-group">-->
<!--                                        <label>Reg. kood</label>-->
<!--                                        <input type="number" class="form-control" v-model="form.reg" @blur="checkDiscount">-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label>Reg. kood / Isikukood *</label>
                                        <input type="text" class="form-control"
                                               :class="{ 'is-invalid': $v.form.reg.$error }"
                                               v-model="$v.form.reg.$model" @blur="checkDiscount">
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label>E-post *</label>
                                        <input type="text" class="form-control"
                                               :class="{ 'is-invalid': $v.form.email.$error }"
                                               v-model="$v.form.email.$model" @blur="checkDiscount">
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <div class="form-group">
                                        <label>Tasumine *</label>
                                        <div class="custom-control custom-radio">
                                            <input type="radio" class="custom-control-input" id="together"
                                                   v-model="form.payment" :value="1">
                                            <label class="custom-control-label" for="together">Tasun tellimuse eest
                                                korraga</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                            <input type="radio" class="custom-control-input" id="separate"
                                                   v-model="form.payment" :value="0">
                                            <label class="custom-control-label" for="separate">Soovin arvet kuu
                                                kaupa</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Lisainfo</label>
                                <textarea class="form-control" v-model="form.info"/>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-danger" @click="order">
                        Edasta tellimus
                    </button>
                    <hr>
                    <div v-html="content.footer_content_lower"></div>
                    <div class="row">
                        <div class="col">
                            <b>Väljaandja</b>: <br>
                            {{ content.footer_publisher }}
                        </div>
                        <div class="col">
                            <b>Toimetus</b>: <br>
                            <a :href="`mailto:${content.footer_email}`">{{ content.footer_email }}</a>, {{ content.footer_phone }}
                        </div>
                        <div class="col">
                            <b>Teostus</b>: <br>
                            {{ content.footer_printer }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AdSizePreviewModal/>
    </div>
</template>

<script>
import AuthService from '@services/auth.service';
import OrderService from '@services/order.service';
import AdSizePreviewModal from './AdSizePreviewModal';
import {mapGetters} from "vuex";
import {email, required} from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    data() {
        return {
            content: {},
            form: {
                name: null,
                reg: null,
                email: null,
                idcode: null,
            },
            total: 0,
            adverts: [],
            maxMbSize: 10,
            accept: ''
        };
    },
    computed: {
        ...mapGetters(['orderForm', 'availableMonths', 'availableDiscounts']),
        isAuthorized() {
            return AuthService.isAuthorized;
        }
    },
    methods: {
        showMonthCheckbox(row, month) {
            if (row.files_allowed && month.qty < 5.95) {
                return true;
            } else if (!row.files_allowed) {
                return true;
            } else {
                return false;
            }
        },
        isDisabled(monthValue) {
            if (monthValue === 7) {
                return 'disabled';
            }
            return false;
        },
        order() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('ORDER', this.form).then(res => {
                    this.$toastr.s(res.message);
                }).then(() => {
                    setTimeout(() => {
                        location.reload();
                    }, 4000);
                });
            }
        },
        showPreview(row) {
            this.$modal.show('ad-size-preview-modal', {row});
        },
        chooseAd(event, row) {
            const advert = this.adverts.find(ad => ad.id == event.target.value);
            row.price = advert.price;
            if (advert.new_price !== null && advert.new_price_at !== null) {
                row.new_price = advert.new_price;
                row.new_price_at = advert.new_price_at;
            } else {
                row.new_price = null;
                row.new_price_at = null;
            }
            row.name = advert.name;
            row.size = advert.size;
            row.months = [];
            row.size_type = advert.size_type;
            row.files_allowed = advert.files_allowed;
            row.max_chars = advert.max_chars;
            this.calcTotalPrice();
        },
        calcRowPrice(price, months, rowIndex) {
            let getsDiscount = false;
            let discountPercent = 0;
            let rowTotal = 0;
            let row = this.form.rows[rowIndex];

            this.availableDiscounts.map(discount => {
                if (discount.month_count <= months && this.form.payment && !this.form.discount) {
                    getsDiscount = true;
                    discountPercent = discount.discount;
                }
            });

            row.months.map(r => {
                rowTotal += r.price;
            });

            if (getsDiscount) {
                let newPrice = this.discountedPrice(rowTotal, discountPercent);
                row.discount = discountPercent;
                row.discount_price = newPrice;
                this.calcTotalPrice();

                return newPrice / 100;
            } else {
                row.discount = null;
                row.discount_price = null;
                this.calcTotalPrice();

                return rowTotal / 100;
            }
        },
        calcTotalPrice() {
            let total = 0;
            this.form.rows.map(row => {
                let rowPrice = 0;
                let rowTotal = 0;
                if (row.months.length) {
                    row.months.map(r => {
                        rowTotal += r.price / 100;
                    });
                    rowPrice = row.discount_price > 0 ? row.discount_price / 100 : rowTotal;
                }
                if (!this.form.payment) {
                    rowPrice = rowPrice / row.months.length;
                }
                total = total + rowPrice;
            });
            this.total = total.toFixed(2);
        },
        discountedPrice(price, per) {
            return price - ((price / 100) * per);
        },
        onFile(file, rowIndex) {
            const row = this.form.rows[rowIndex];
            row.files.push({
                id: row.files.length + 1,
                name: file.name,
                size: file.size,
                type: file.type,
                file: null
            });
        },
        onLoad(base64, rowIndex) {
            const row = this.form.rows[rowIndex];
            const file = row.files.find(file => file.id === row.files.length);
            file.file = base64;
        },
        onSizeExceeded(size) {
            alert(`Pildi suurus ${Math.round((size + Number.EPSILON) * 100) / 100}Mb ületab limiiti ${this.maxMbSize}Mb!`);
        },
        removeFile(rowIndex, fileIndex) {
            const row = this.form.rows[rowIndex];
            row.files.splice(fileIndex, 1);
        },
        addMonth(month, row) {
            if (!row.months.find(mo => mo.month === month)) {
                if (moment(month).isSameOrAfter(row.new_price_at)) {
                    row.months.push({month: month, price: row.new_price});
                } else {
                    row.months.push({month: month, price: row.price});
                }
            } else {
                const index = row.months.findIndex(mo => mo.month === month);
                row.months.splice(index, 1);
            }
        },
        removeAd(i) {
            if (!window.confirm('Kustutame?')) {
                return false;
            }
            this.$store.commit('REMOVE_ORDER_ROW', i);
            this.calcTotalPrice();
        },
        getAvailableMonths() {
            this.$store.dispatch('GET_AVAILABLE_MONTHS');
        },
        getDiscounts() {
            this.$store.dispatch('GET_DISCOUNTS');
        },
        getAdverts() {
            OrderService.adverts().then(res => this.adverts = res);
        },
        checkDiscount() {
            const form = {name: this.form.name, email: this.form.email, reg: this.form.reg};
            if (!form.name && !form.reg && !form.email) {
                return;
            }
            OrderService.checkDiscount(form).then(res => this.form.discount = res);
        },
        addAdvert() {
            let advert = {
                ad_id: null,
                price: 0,
                new_price: null,
                new_price_at: null,
                months: [],
                files: [],
                discount: 0,
                discount_price: 0,
                size: null,
                info: '',
                files_allowed: false
            };
            this.form.rows.push(advert);
        },
        fillData(val) {
            if (val) {
                this.form = val;
            }
        }
    },
    components: {
        AdSizePreviewModal
    },
    created() {
        this.fillData(this.orderForm);
        this.getAdverts();
        this.getAvailableMonths();
        this.getDiscounts();
        this.calcTotalPrice();
        this.$store.dispatch('GET_PUBLIC_CONTENT').then(res => {
            this.content = res;
        });
    },
    validations: {
        form: {
            rows: {
                $each: {
                    months: {
                        required
                    },
                    ad_id: {
                        required
                    }
                }
            },
            name: {
                required
            },
            reg: {
                required
            },
            payment: {
                required
            },
            email: {
                required,
                email
            }
        }
    }
};
</script>

<style scoped>
.fa-times {
    cursor: pointer !important;
}
</style>
