import http from './http.service';

class AdvertService {
    constructor() {
        if(AdvertService.instance) {
            return AdvertService.instance;
        }

        AdvertService.instance = this;
    }

    adverts() {
        return http.get('/api/adverts').then(res => res.data);
    }

    store(data) {
        return http.post('/api/adverts/store', data).then(res => res.data);
    }

    show(advertId) {
        return http.get(`/api/adverts/${advertId}/show`).then(res => res.data);
    }

    update(advertId, data) {
        return http.put(`/api/adverts/${advertId}/update`, data).then(res => res.data);
    }

    delete(advertId) {
        return http.delete(`/api/adverts/${advertId}/destroy`).then(res => res.data);
    }
}

export default new AdvertService();
