<template>
    <modal name="edit-company-modal" height="auto" :scrollable="true" @before-open="beforeOpen"
           @before-close="beforeClose">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h3 class="modal-title">Muuda</h3>
                <i class="fa fa-times" @click="closeModal()"></i>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Nimi*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.name.$error }"
                           v-model="$v.form.name.$model">
                </div>
                <div class="form-group">
                    <label>Reg. kood</label>
                    <input type="text"
                           class="form-control"
                           v-model="form.reg">
                </div>
                <div class="form-group">
                    <label>Isikukood*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.idcode.$error }"
                           v-model="$v.form.idcode.$model">
                </div>
                <div class="form-group">
                    <label>E-post*</label>
                    <input type="email"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.email.$error }"
                           v-model="$v.form.email.$model">
                </div>
                <div class="form-group">
                    <label>Allahindlus %</label>
                    <input type="number"
                           class="form-control"
                           v-model="form.discount">
                </div>
                <div class="form-group text-center">
                    <button @click="update" class="btn btn-success">
                        Salvesta
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {required} from 'vuelidate/lib/validators';

export default {
    name: 'EditCompanyModal',
    data() {
        return {
            id: null,
            form: {},
        };
    },
    methods: {
        beforeOpen(event) {
            this.id = event.params.company.id;
            this.form = Object.assign({}, event.params.company);
        },
        beforeClose() {
            this.$v.$reset();
        },
        closeModal() {
            this.$modal.hide('edit-company-modal');
        },
        update() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('UPDATE_COMPANY', {id: this.id, data: this.form}).then(() => {
                    this.$toastr.s('Savestatud');
                    this.form = {};
                    this.id = null;
                    this.closeModal();
                });
            }
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            email: {
                required
            },
            idcode: {
                required
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.v--modal-overlay {
    z-index: 1000;
}

.modal-header {
    .fa-times {
        cursor: pointer;
    }
}
</style>
