import http from './http.service';

class DiscountService {
    constructor() {
        if(DiscountService.instance) {
            return DiscountService.instance;
        }

        DiscountService.instance = this;
    }

    discounts() {
        return http.get('/api/settings/discounts').then(res => res.data);
    }

    store(data) {
        return http.post('/api/settings/discounts/store', data).then(res => res.data);
    }

    show(discountId) {
        return http.get(`/api/settings/discounts/${discountId}/show`).then(res => res.data);
    }

    update(discountId, data) {
        return http.put(`/api/settings/discounts/${discountId}/update`, data).then(res => res.data);
    }

    delete(discountId) {
        return http.delete(`/api/settings/discounts/${discountId}/destroy`).then(res => res.data);
    }
}

export default new DiscountService();
