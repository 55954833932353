<template>
    <div class="container-fluid">
        <Header name="Töölaud"/>
        <div class="card">
            <div class="card-body">
                <ul class="nav nav-tabs">
                    <li class="nav-item" v-for="month in availableMonths">
                        <a class="nav-link" :id="`month_${month.date}`"
                           :class="{'active':aMonth.date === month.date}" href="#" @click="setActiveMonth(month)">
                            {{ month.date | formatMonthAndYear }}
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <MonthData :month="aMonth"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from '@components/layout/Header';
    import MonthData from './MonthData';
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                activeMonth: {}
            };
        },
        computed: {
            ...mapGetters(['availableMonths']),
            aMonth() {
                return this.activeMonth;
            }
        },
        methods: {
            setActiveMonth(month) {
                this.activeMonth = month;
            },
            getAvailableMonths() {
                this.$store.dispatch('GET_AVAILABLE_MONTHS').then(res => {
                    this.activeMonth = res[0];
                });
            },
        },
        components: {
            Header,
            MonthData
        },
        created() {
            this.getAvailableMonths();
            this.$store.dispatch('INIT_ADVERTS');
        }
    };
</script>
