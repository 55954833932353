import Vue from 'vue';
import OrderService from '@services/order.service';

const state = initialState;

const mutations = {
    ['SET_ORDER_ROWS'](state, data) {
        state.order_form = data;
    },
    ['REMOVE_ORDER_ROW'](state, index) {
        state.order_form.rows.splice(index, 1);
    },
    ['SET_AVAILABLE_MONTHS'](state, data) {
        state.available_months = data;
    },
    ['SET_DISCOUNTS'](state, data) {
        state.available_discounts = data;
    },
    ['RESET_ORDER'](state) {
        const s = initialState();
        Object.keys(s).forEach(key => state[key] = s[key]);
    }
};

const actions = {
    ['GET_AVAILABLE_MONTHS']({commit, getters}) {
        if (getters.availableMonths.length) {
            return getters.availableMonths;
        }
        return OrderService.availableMonths().then(res => {
            commit('SET_AVAILABLE_MONTHS', res);
            return res;
        });
    },
    ['GET_DISCOUNTS']({commit, getters}) {
        if (getters.discounts.length) {
            return getters.discounts;
        }
        OrderService.availableDiscounts().then(res => {
            commit('SET_DISCOUNTS', res);
        });
    },
    ['ORDER']({commit}, data) {
        return OrderService.store(data).then(res => {
            return res;
        });
    }
};

const getters = {
    orderForm(state) {
        return state.order_form;
    },
    availableMonths(state) {
        return state.available_months;
    },
    availableDiscounts(state) {
        return state.available_discounts;
    }
};

function initialState() {
    return {
        order_form: {
            rows: [
                {
                    ad_id: null,
                    price: 0,
                    new_price: null,
                    new_price_at: null,
                    months: [],
                    files: [],
                    discount: 0,
                    discount_price: 0,
                    size: null,
                    info: '',
                    files_allowed: false
                }
            ],
            name: '',
            email: '',
            payment: 1,
            discount: false,
            info: '',
            reg: ''
        },
        available_months: [],
        available_discounts: []
    }
}

export default {
    state,
    mutations,
    actions,
    getters
};
