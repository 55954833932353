<template>
    <div>
        <div class="mb-3"
             :class="{
                 'row': inline && isLabelSlot,
                 'd-flex': inline && isLabelSlot,
             }">
            <label v-if="isLabelSlot" :for="id" :class="labelClass">
                <slot name="label"/>
            </label>
            <div :class="{'col-md-7': labelClass.length}" class="bg-white">
                <quill-editor
                    :id="id"
                    ref="editor"
                    v-model="val"
                    :name="name"
                    :placeholder="placeholder"
                    :style="inputStyle"
                    :options="editorOption"
                    @blur="onBlur($event)"/>
            </div>
        </div>
    </div>
</template>

<script>
import {quillEditor} from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    [{indent: '-1'}, {indent: '+1'}],
    [{size: ['small', false, 'large', 'huge']}],
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{color: []}, {background: []}],
    [{align: []}],
    ['link']
];
export default {
    name: 'AppQuill',
    components: {quillEditor},
    props: {
        id: {
            type: [String, Number],
            default: 'app-quill'
        },
        value: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'app-quill'
        },
        rules: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        rows: {
            type: Number,
            default: 3
        },
        inline: Boolean
    },
    data: () => ({
        editorOption: {
            theme: 'snow',
            modules: {
                toolbar: toolbarOptions
            }
        }
    }),
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(val) {
                this.resize();
                this.$emit('input', val);
            }
        },
        labelClass() {
            if (this.inline) {
                return 'col-md-4 col-form-label text-md-right';
            }

            return '';
        },
        isLabelSlot() {
            return !!this.$slots.label;
        },
        inputStyle() {
            return {
                'min-height': this.inputHeight
            };
        }
    },
    mounted() {
        this.resize();
    },
    methods: {
        onBlur(value) {
            this.$emit('blur', value);
        },
        resize() {
            this.inputHeight = `${this.$refs.editor.scrollHeight}px`;
        }
    }
};
</script>
