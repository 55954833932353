<template>
    <div class="container-fluid">
        <Header :name="pageName" :addNew="addNew" @create="create"/>
        <div class="card mt-3">
            <div class="card-body">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <router-link to="/auth/settings/discounts" class="nav-link" tag="a" exact>
                            Allahindlused
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/auth/settings/other" class="nav-link" tag="a" exact>
                            Muu
                        </router-link>
                    </li>
                </ul>
                <div class="tab-content">
                    <router-view name="settings"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from '@components/layout/Header';

    export default {
        data() {
            return {
                addNew: false,
                pageName: 'Seaded'
            }
        },
        methods: {
            create() {
                this.$modal.show('create-discount-modal');
            },
            setPage(data) {
                this.addNew = data.addNew;
                this.pageName = data.pageName;
            }
        },
        components: {
            Header
        }
    };
</script>
