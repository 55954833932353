<template>
    <div class="d-flex flex-column">
        <Nav></Nav>
        <div class="d-flex flex-md-grow-1">
            <router-view name="base"/>
        </div>
    </div>
</template>

<script>
    import Nav from '../common/components/layout/Nav';

    export default {
        data() {
            return {}
        },
        computed: {},
        components: {
            Nav
        },
        created() {
        }
    };
</script>

<style scoped>
    .d-flex.flex-column {
        min-height: 100vh;
    }
</style>
