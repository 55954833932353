<template>
    <div class="tab-pane fade show active">
        <table class="table table-hover bg-white mt-3">
            <thead>
            <tr>
                <th>Tellimuse nr</th>
                <th>Tellija</th>
                <th>Kuupäev</th>
                <th>Kuu(d)</th>
                <th>Pindade arv</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(order, i) in unapprovedOrders" :key="order.id">
                <td>{{ order.id }}</td>
                <td>
                    <router-link :to="{name:'orders.show', params:{order_id:order.id}}">
                        {{ order.company.name }}
                    </router-link>
                </td>
                <td>
                    {{ order.created_at | formatDate }}
                    <template v-if="order.approved_at">
                        | kinnitatud: {{ order.approved_at | formatDate }}
                    </template>
                </td>
                <td>
                    <template v-if="order.rows.length == 1">
                        <p v-for="month in order.rows[0].months" class="m-0">
                            {{ month.month ? month.month : month }}
                        </p>
                    </template>
                    <template v-else>
                        {{ order.rows.length }}
                    </template>
                </td>
                <td>{{ order.rows.length }}</td>
                <td>
                    <div class="pull-right">
                        <router-link :to="{name:'orders.edit', params:{order_id:order.id}}" tag="button"
                                     class="btn btn-outline-primary btn-sm" v-if="!order.approved_at">
                            Muuda
                        </router-link>
                        <button type="button" class="btn btn-outline-danger btn-sm"
                                @click="confirmOrder(order.id, 'Eemaldame kinnituse?')" v-if="order.approved_at">
                            Eemalda kinnitus
                        </button>
                        <button type="button" class="btn btn-outline-success btn-sm"
                                @click="confirmOrder(order.id, 'Kinnitame?')" v-else>
                            Kinnita
                        </button>
                        <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteOrder(order.id)"
                                v-if="!order.approved_at">
                            Kustuta
                        </button>
                        <button type="button" class="btn btn-outline-success btn-sm"
                                @click="archiveOrder(order.id, 'Arhiveerime?')"
                                v-if="order.approved_at && !order.archived_at">
                            Arhiveeri
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import OrdersService from '@services/orders.service';
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters(['unapprovedOrders'])
        },
        methods: {
            archiveOrder(orderId, msg) {
                if (!window.confirm(msg)) {
                    return false;
                }
                this.$store.dispatch('ARCHIVE_ORDER', orderId).then(res => {
                    this.$toastr.s(res.message);
                });
            },
            confirmOrder(orderId, msg) {
                if (!window.confirm(msg)) {
                    return false;
                }
                this.$store.dispatch('CONFIRM_ORDER', orderId).then(res => {
                    this.$toastr.s(res.message);
                });
            },
            deleteOrder(orderId) {
                if (!window.confirm('Kustutame?')) {
                    return false;
                }
                OrdersService.delete(orderId).then(res => {
                    this.$store.commit('REMOVE_ORDER', orderId);
                    this.$toastr.s(res.message);
                });
            },
        },
        created() {
            this.$store.dispatch('INIT_CURRENT_ORDERS');
        }
    };
</script>
