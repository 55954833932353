<template>
    <div class="tab-pane fade show active">
        <table class="table table-hover mt-3">
            <thead>
            <tr style="background-color: rgba(0, 0, 0, 0.035)">
                <td colspan="2">
                    <h5 class="mb-0 mt-2">
                        Vaba pind: <b>{{ freeSpace }}lk</b>, broneeritud pind: <b>{{ totalSpace }}lk</b>
                    </h5>
                </td>
                <td colspan="4">
                    <button class="btn btn-outline-success float-right ml-2" @click="archive" v-if="rows.length && canArchive">
                        Arhiveeri {{ month.short_name }} {{ month.year }}
                    </button>
                    <button class="btn btn-primary float-right ml-2" @click="downloadXls(month.date, 'unpaid')">
                        Maksmata {{ month.short_name }} {{ month.year }} <i class="fa fa-file-excel-o ml-1"/>
                    </button>
                    <button class="btn btn-success float-right ml-2" @click="downloadXls(month.date, 'approved')">
                        Kinnitatud {{ month.short_name }} {{ month.year }} <i class="fa fa-file-excel-o ml-1"/>
                    </button>
                    <select v-model="adType" class="custom-select-sm col-2 ml-2 mt-1 float-right">
                        <option :value="null">- vali -</option>
                        <option v-for="ad in adverts" :value="ad.id">{{ ad.name }}</option>
                    </select>
                    <div class="custom-control custom-checkbox float-right ml-2 mt-2">
                        <input type="checkbox" class="custom-control-input" id="rowAdsCheck" v-model="rowAds">
                        <label class="custom-control-label" for="rowAdsCheck">Rea kuulutused</label>
                    </div>
                    <div class="custom-control custom-checkbox float-right ml-2 mt-2">
                        <input type="checkbox" class="custom-control-input" id="unpaidCheck" v-model="unpaid">
                        <label class="custom-control-label" for="unpaidCheck">Maksmata</label>
                    </div>
                </td>
            </tr>
            <tr>
                <th>Tellimuse nr</th>
                <th>Tellija</th>
                <th>Tellimuse kuupäev</th>
                <th>Pind</th>
                <th>Hind €</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, i) in filteredAds" :key="i">
                <td>
                    {{ row.order.id }}
                    <i v-if="row.order.paid_at === null" class="fa fa-exclamation text-danger" title="Maksmata"/>
                </td>
                <td>
                    <router-link :to="{name:'orders.show', params:{order_id:row.order.id}}">
                        {{ row.order.company.name }}
                    </router-link>
                </td>
                <td>
                    {{ row.order.created_at | formatDate }}
                    <template v-if="row.order.approved_at">
                        | kinnitatud: {{ row.order.approved_at | formatDate }}
                    </template>
                </td>
                <td>{{ row.advert.name }}</td>
                <td>
                    <small v-if="row.discount">(-{{ row.discount }}%)</small>
                    {{ calcPrice(row) }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import OrdersService from '@services/orders.service';
import {filters} from '@/filters';
import {mapGetters} from "vuex";

export default {
    name: "MonthData",
    props: ['month'],
    data() {
        return {
            rows: [],
            rowAds: false,
            unpaid: false,
            adType: null
        }
    },
    watch: {
        month(val) {
            OrdersService.getMonthOrders(val.date).then(res => {
                this.rows = [];
                this.rows = res;
            });
        }
    },
    computed: {
        ...mapGetters(['adverts']),
        filteredAds() {
            return this.rows.filter(row => {
                return (
                    (this.unpaid ? row.order.paid_at === null : true) &&
                    (this.rowAds ? row.advert.files_allowed === !this.rowAds : true) &&
                    (this.adType ? row.ad_id === this.adType : true)
                );
            });
        },
        totalSpace() {
            let all = 0;
            this.rows.map(row => {
                const size = row.advert.size;
                if (size === '1') {
                    all += 1;
                } else if (size === '1/2') {
                    all += 0.5;
                } else if (size === '1/4') {
                    all += 0.25;
                } else if (size === '1/6') {
                    all += 0.166666;
                } else if (size === '1/12') {
                    all += 0.083333;
                }
            });
            return all.toFixed(3);
        },
        freeSpace() {
            let all = 6;
            this.rows.map(row => {
                const size = row.advert.size;
                if (size === '1') {
                    all -= 1;
                } else if (size === '1/2') {
                    all -= 0.5;
                } else if (size === '1/4') {
                    all -= 0.25;
                } else if (size === '1/6') {
                    all -= 0.166666;
                } else if (size === '1/12') {
                    all -= 0.083333;
                }
            });
            return all.toFixed(3);
        },
        canArchive() {
            let can = true;
            for (let i = 0; i < this.rows.length; i++) {
                if (!this.rows[i].order.paid_at) {
                    can = false;
                    break;
                }
            }
            return can;
        }
    },
    methods: {
        archive() {
            if (!window.confirm('Arhiveeri kõik kuu tellimused?')) {
                return false;
            }
            this.rows.map(row => {
                this.$store.dispatch('ARCHIVE_ORDER', row.order.id);
            });

            OrdersService.getMonthOrders(this.month.date).then(res => {
                this.rows = [];
                this.rows = res;
            });
            this.$toastr.s('Kõik tellimused arhiveeritud');
        },
        downloadXls(date, status) {
            const fileName = `${filters.formatMonthAndYear(date)}_tellimused.xlsx`;
            OrdersService.getMonthOrdersXls(date, status, fileName);
        },
        calcPrice(row) {
            const rowFullPrice = (row.price / row.months.length) / 100;
            const discount = row.discount;

            return (rowFullPrice - ((discount / 100) * rowFullPrice)).toFixed(2);
        }
    },
    created() {
        setTimeout(() => {
            OrdersService.getMonthOrders(this.month.date).then(res => {
                this.rows = [];
                this.rows = res;
            });
        }, 1000);
    }
}
</script>
