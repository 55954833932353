import Vue from 'vue';
import OrdersService from '@services/orders.service';

const state = {
    orders: [],
    archived_orders: [],
};

const mutations = {
    ['SET_ORDERS'](state, data) {
        state.orders = data;
    },
    ['SET_ARCHIVED_ORDERS'](state, data) {
        state.archived_orders = data;
    },
    ['REMOVE_ORDER'](state, orderId) {
        const index = state.orders.findIndex(i => i.id === orderId);
        state.orders.splice(index, 1);
    },
    ['UPDATE_ORDER'](state, order) {
        const index = state.orders.findIndex(i => i.id === order.id);
        Vue.set(state.orders, index, order);
    },
    ['ARCHIVE'](state, order) {
        if (order.archived_at) {
            const index = state.orders.findIndex(i => i.id === order.id);
            state.orders.splice(index, 1);
            state.archived_orders.push(order);
        } else {
            const index = state.archived_orders.findIndex(i => i.id === order.id);
            state.archived_orders.splice(index, 1);
            state.orders.push(order);
        }
    },
};

const actions = {
    async ['INIT_CURRENT_ORDERS']({commit, getters}) {
        return await OrdersService.orders().then(res => {
            commit('SET_ORDERS', res);

            return res;
        });
    },
    async ['INIT_ARCHIVED_ORDERS']({commit, getters}) {
        return await OrdersService.archivedOrders().then(res => {
            commit('SET_ARCHIVED_ORDERS', res);

            return res;
        });
    },
    ['CONFIRM_ORDER']({commit}, orderId) {
        return OrdersService.confirm(orderId).then(res => {
            commit('UPDATE_ORDER', res.order);
            return res;
        });
    },
    ['MARK_ORDER_PAID']({commit}, orderId) {
        return OrdersService.markPaid(orderId).then(res => {
            commit('UPDATE_ORDER', res.order);
            return res;
        });
    },
    ['ARCHIVE_ORDER']({commit}, orderId) {
        return OrdersService.archive(orderId).then(res => {
            commit('ARCHIVE', res.order);
            return res;
        });
    },
    ['UPDATE_ORDER']({commit}, data) {
        return OrdersService.update(data.id, data).then(res => {
            commit('UPDATE_ORDER', res.order);
            return res;
        });
    }
};

const getters = {
    unapprovedOrders(state) {
        return Array.isArray(state.orders) && state.orders.length ? state.orders.filter(order => order.approved_at === null) : [];
    },
    approvedOrders(state) {
        return Array.isArray(state.orders) && state.orders.length ? state.orders.filter(order => order.approved_at !== null && order.paid_at === null) : [];
    },
    paidOrders(state) {
        return Array.isArray(state.orders) && state.orders.length ? state.orders.filter(order => order.approved_at !== null && order.paid_at !== null) : [];
    },
    archivedOrders(state) {
        return Array.isArray(state.archived_orders) && state.archived_orders.length ? state.archived_orders : [];
    },
    allOrders(state) {
        return Array.isArray(state.orders) && state.orders.length ? state.orders : [];
    },
    currentOrderById: (state) => (id) => {
        return state.orders.find(order => order.id === id);
    },
    archivedOrderById: (state) => (id) => {
        return state.archived_orders.find(order => order.id === id);
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
