<template>
    <div class="container-fluid" v-if="company">
        <Header name="Klient"/>
        <div class="card mt-3">
            <div class="card-body">
                <h5 class="card-title">
                    {{ company.name }}
                </h5>
                <hr>
                <p class="mb-1">E-post: {{ company.email }}</p>
                <p class="mb-1">Reg. nr: {{ company.reg }}</p>
                <p class="mb-1">Allahindlus: {{ company.discount }}%</p>
                <hr>
                <h5>Tellimused</h5>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Kuupäev</th>
                        <th>Kinnitatud</th>
                        <th>Pindade arv</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(order, i) in company.orders" :key="order.id">
                        <td>{{ i + 1}}</td>
                        <td>
                            <router-link :to="{name:'orders.show', params:{order_id:order.id}}">
                                {{ order.created_at | formatDate }}
                            </router-link>
                        </td>
                        <td>
                            <template v-if="order.approved_at">
                                {{ order.approved_at | formatDate }}
                            </template>
                            <template v-else>
                                Ei
                            </template>
                        </td>
                        <td>{{ order.rows.length }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from '@components/layout/Header';
    import CompanyService from '@services/company.service';

    export default {
        data() {
            return {
                company: null
            }
        },
        methods: {
            getCompany() {
                const company = this.$store.getters.companyById(this.$route.params.company_id);
                if (!company) {
                    CompanyService.show(this.$route.params.company_id).then(res => this.company = res);
                } else {
                    this.company = company;
                }
            }
        },
        components: {
            Header
        },
        created() {
            this.getCompany();
        }
    };
</script>
