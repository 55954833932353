import Vue from 'vue';
import DiscountService from '@services/discount.service';

const state = {
    discounts: []
};

const mutations = {
    ['SET_DISCOUNTS'](state, data) {
        state.discounts = data;
    },
    ['REMOVE_DISCOUNT'](state, discountId) {
        const index = state.discounts.findIndex(i => i.id === discountId);
        state.discounts.splice(index, 1);
    },
    ['CREATE_DISCOUNT'](state, data) {
        state.discounts.push(data);
    },
    ['UPDATE_DISCOUNT'](state, data) {
        const index = state.discounts.findIndex(i => i.id === data.id);
        Vue.set(state.discounts, index, data);
    }
};

const actions = {
    async ['INIT_DISCOUNTS']({ commit, getters }) {
        if(getters.discounts.length) {
            return getters.discounts;
        }

        return await DiscountService.discounts().then(res => {
            commit('SET_DISCOUNTS', res);

            return res;
        });
    },
    ['CREATE_DISCOUNT']({ commit }, data) {
        DiscountService.store(data).then(res => {
            commit('CREATE_DISCOUNT', res);
        });
    },
    ['UPDATE_DISCOUNT']({ commit }, { id, data }) {
        DiscountService.update(id, data).then(res => {
            commit('UPDATE_DISCOUNT', res);
        });
    }
};

const getters = {
    discounts(state) {
        return Array.isArray(state.discounts) && state.discounts.length ? state.discounts : [];
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
