<template>
    <div class="container-fluid">
        <Header name="Sisuhaldus" />
        <div class="form-group">
            <app-quill id="footer_content_upper" v-model="form.footer_content_upper" name="footer_content_upper">
                <template #label>
                    Sisu
                </template>
            </app-quill>
        </div>
        <div class="form-group">
            <app-quill id="footer_content_lower" v-model="form.footer_content_lower" name="footer_content_lower">
                <template #label>
                    Jaluse sisu
                </template>
            </app-quill>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Väljaandja*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.footer_publisher.$error }"
                           v-model="$v.form.footer_publisher.$model">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>E-post*</label>
                    <input type="email"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.footer_email.$error }"
                           v-model="$v.form.footer_email.$model">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Telefon*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.footer_phone.$error }"
                           v-model="$v.form.footer_phone.$model">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Teostus*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.footer_printer.$error }"
                           v-model="$v.form.footer_printer.$model">
                </div>
            </div>
        </div>
        <div class="form-group">
            <button @click="update" class="btn btn-success">
                Salvesta
            </button>
        </div>
    </div>
</template>

<script>
    import AppQuill from '@components/Quill';
    import Header from '@components/layout/Header';
    import {required} from "vuelidate/lib/validators";

    export default {
        data() {
            return {
                form: {
                    footer_content_upper: '',
                    footer_content_lower: '',
                    footer_publisher: '',
                    footer_email: '',
                    footer_phone: '',
                    footer_printer: '',
                }
            }
        },
        methods: {
            update() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.$store.dispatch('UPDATE_CONTENT', this.form).then(() => {
                        this.$toastr.s('Savestatud');
                    });
                }
            }
        },
        components: {
            Header,
            AppQuill
        },
        created() {
            this.$store.dispatch('GET_CONTENT').then(res => {
                this.form = res;
            });
        },
        validations: {
            form: {
                footer_publisher: {
                    required
                },
                footer_email: {
                    required
                },
                footer_phone: {
                    required
                },
                footer_printer: {
                    required
                }
            }
        }
    };
</script>
