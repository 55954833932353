import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=00928302&scoped=true"
import script from "./Wrapper.vue?vue&type=script&lang=js"
export * from "./Wrapper.vue?vue&type=script&lang=js"
import style0 from "./Wrapper.vue?vue&type=style&index=0&id=00928302&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00928302",
  null
  
)

export default component.exports