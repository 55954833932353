import http from './http.service';

class SettingsService {
    constructor() {
        if(SettingsService.instance) {
            return SettingsService.instance;
        }

        SettingsService.instance = this;
    }

    getSettings() {
        return http.get(`/api/settings`).then(res => res.data);
    }

    update(settingsId, data) {
        return http.put(`/api/settings/${settingsId}/update`, data).then(res => res.data);
    }

    migrate() {
        return http.get(`/api/settings/migrate`).then(res => res.data);
    }

    rollback() {
        return http.get(`/api/settings/rollback`).then(res => res.data);
    }
}

export default new SettingsService();
