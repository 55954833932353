<template>
    <div class="container-fluid" v-if="order">
        <Header :name="`Tellimus nr: ${order.id}`"/>
        <div class="card mt-3">
            <div class="card-body">
                <h5 class="card-title">
                    <router-link :to="{name:'companies.show', params:{company_id:order.company_id}}">
                        {{ order && order.company.name }}
                    </router-link>
                    <router-link :to="{name:'orders.edit', params:{order_id:order.id}}" tag="button"
                                 class="btn btn-outline-primary float-right"
                                 v-if="!order.approved_at && !order.archived_at">
                        Muuda
                    </router-link>
                    <button type="button" class="btn btn-outline-danger float-right mr-1"
                            @click="confirmOrder(order.id, 'Eemaldame kinnituse?')"
                            v-if="order.approved_at && !order.archived_at">
                        Eemalda kinnitus
                    </button>
                    <button type="button" class="btn btn-outline-success float-right mr-1"
                            @click="confirmOrder(order.id, 'Kinnitame?')"
                            v-if="!order.approved_at && !order.archived_at">
                        Kinnita
                    </button>
                    <button type="button" class="btn btn-outline-primary float-right mr-1"
                            @click="archiveOrder(order.id, 'Taastame arhiivist?')"
                            v-if="order.approved_at && order.archived_at">
                        Taasta
                    </button>
                    <button type="button" class="btn btn-outline-success float-right mr-1"
                            @click="archiveOrder(order.id, 'Arhiveerime?')"
                            v-if="order.approved_at && !order.archived_at">
                        Arhiveeri
                    </button>
                </h5>
                <h6 class="card-subtitle mb-2 text-muted">Tellitud: {{ order.created_at | formatDate }}</h6>
                <div class="custom-control custom-switch" v-if="order.approved_at">
                    <input type="checkbox" class="custom-control-input" :checked="order.paid_at"
                           @click="markOrderPaid(order.id)" :id="`payment_${order.id}`"/>
                    <label v-if="!order.paid_at" class="custom-control-label"
                           :for="`payment_${order.id}`">Maksmata</label>
                    <label v-else class="custom-control-label" :for="`payment_${order.id}`">Makstud</label>
                </div>
                <hr>
                <p>Lisainfo</p>
                <p>{{ order.info || '-' }}</p>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Pind</th>
                        <th>Hind €</th>
                        <th>Kuu(d)</th>
                        <th>Allahindlus %</th>
                        <th>Failid/info</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, rowIndex) in order.rows">
                        <td>{{ rowIndex + 1 }}</td>
                        <td>{{ row.advert.name }}</td>
                        <td>
                            <small v-if="row.discount">(-{{ row.discount }}%)</small>
                            {{
                                ((row.price * row.months.length) - ((row.price * row.months.length / 100) * row.discount)).toFixed(2)
                            }}
                        </td>
                        <td>
                            <p v-for="month in row.months" class="m-0">
                                {{ month.month ? month.month : month | formatMonthAndYear }}
                            </p>
                        </td>
                        <td>{{ row.discount }}</td>
                        <td>
                            <template v-for="file in row.files">
                                <a href="#" @click="donwloadFile(file)">{{ file.filename }}</a><br>
                            </template>
                            <span v-if="row.info">{{ row.info }}</span>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colspan="5">KOKKU</th>
                        <th>{{ total }} €</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@components/layout/Header';
import OrdersService from '@services/orders.service';

export default {
    data() {
        return {
            order: {
                rows: [],
                company: {
                    name: null
                }
            },
            total: 0
        }
    },
    methods: {
        archiveOrder(orderId, msg) {
            if (!window.confirm(msg)) {
                return false;
            }
            this.$store.dispatch('ARCHIVE_ORDER', orderId).then(res => {
                this.setRows(res.order)
                this.$toastr.s(res.message);
            });
        },
        markOrderPaid(orderId) {
            this.$store.dispatch('MARK_ORDER_PAID', orderId).then(res => {
                this.setRows(res.order)
                this.$toastr.s(res.message);
            });
        },
        calcTotalPrice() {
            let total = 0;
            this.order.rows.map(row => {
                let rowPrice = (row.price * row.months.length) - ((row.price * row.months.length / 100) * row.discount);
                total = total + rowPrice;
            });
            this.total = total;
        },
        confirmOrder(orderId) {
            if (!window.confirm('Kinnitame?')) {
                return false;
            }
            this.$store.dispatch('CONFIRM_ORDER', orderId).then(res => {
                this.setRows(res.order)
                this.$toastr.s(res.message);
            });
        },
        getOrder() {
            const order = this.$store.getters.currentOrderById(this.$route.params.order_id);
            if (!order) {
                OrdersService.show(this.$route.params.order_id).then(res => this.setRows(res));
            } else {
                this.setRows(order);
            }
        },
        setRows(order) {
            let rows = [];
            order.rows.map(row => {
                rows.push({
                    id: row.id,
                    advert: row.advert,
                    files: row.files,
                    order_id: row.order_id,
                    ad_id: row.ad_id,
                    months: row.months,
                    discount: row.discount,
                    price: row.price / row.months.length / 100,
                    info: row.info
                });
            });
            this.order = {
                id: order.id,
                approved_at: order.approved_at,
                archived_at: order.archived_at,
                paid_at: order.paid_at,
                company: order.company,
                company_id: order.company_id,
                info: order.info,
                rows
            };
            this.calcTotalPrice();
        },
        donwloadFile(file) {
            OrdersService.download(file.id, file.filename, file.mime);
        },
        getDiscounts() {
            this.$store.dispatch('GET_DISCOUNTS');
        },
    },
    components: {
        Header
    },
    created() {
        this.getOrder();
    }
};
</script>

