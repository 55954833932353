import http from './http.service';
import {download} from '@services/helper.service';

class OrdersService {
    constructor() {
        if (OrdersService.instance) {
            return OrdersService.instance;
        }

        OrdersService.instance = this;
    }

    orders() {
        return http.get('/api/orders').then(res => res.data);
    }

    archivedOrders() {
        return http.get('/api/orders/archived').then(res => res.data);
    }

    confirm(orderId) {
        return http.put(`/api/orders/${orderId}/confirm`).then(res => res.data);
    }

    markPaid(orderId) {
        return http.put(`/api/orders/${orderId}/paid`).then(res => res.data);
    }

    archive(orderId) {
        return http.put(`/api/orders/${orderId}/archive`).then(res => res.data);
    }

    update(orderId, data) {
        return http.put(`/api/orders/${orderId}/update`, data).then(res => res.data);
    }

    show(orderId) {
        return http.get(`/api/orders/${orderId}/show`).then(res => res.data);
    }

    download(orderRowFileId, fileName, mimeType) {
        return http.download(`/api/orders/${orderRowFileId}/download`).then(res => {
            download(res.data, fileName, mimeType);
        });
    }

    delete(orderId) {
        return http.delete(`/api/orders/${orderId}/destroy`).then(res => res.data);
    }

    getMonthOrders(date) {
        return http.get(`/api/orders/month/${date}`).then(res => res.data);
    }

    getMonthOrdersXls(date, status, fileName) {
        return http.download(`/api/orders/month/${date}/${status}/xls`).then(res => {
            download(res.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        });
    }
}

export default new OrdersService();
