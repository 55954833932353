import store from '../../store';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';

export function translate(string) {
    const translation = JSON.parse(string);
    if (!translation[store.getters.language]) {
        return `Missing [${store.getters.language}]`;
    }
    return translation[store.getters.language];
}

export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }

    return changes(object, base);
}

export function search(query, str) {
    return str
        .toLowerCase()
        .trim()
        .includes(query.toLowerCase().trim());
}

export function cleanQueryObj(obj) {

    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
        if (obj.trashed === false) {
            delete obj.trashed;
        }
    }

    return obj;
}

export function download(data, filename, mimeType) {
    const fileURL = window.URL.createObjectURL(new Blob([data], {type: mimeType}));
    const a = document.createElement('a');
    a.href = fileURL;
    a.setAttribute('download', `${filename}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function objectNotEmpty(obj) {
    return Object.keys(obj).length;
}

export function scrollToTop() {
    return window.scrollTo(0,0);
}
