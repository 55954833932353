import Settings from './index';
import Discounts from './Discounts';
import Other from './Other';

export default [
    {
        path: 'settings',
        components: {base: Settings},
        children: [
            {
                path: 'discounts',
                name: 'discounts',
                components: {settings: Discounts}
            },
            {
                path: 'other',
                name: 'other',
                components: {settings: Other}
            }
        ]
    }
];
