<template>
    <modal name="edit-discount-modal" height="auto" :scrollable="true" @before-open="beforeOpen" @before-close="beforeClose">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h3 class="modal-title">Muuda</h3>
                <i class="fa fa-times" @click="closeModal()"></i>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Kuude kogus (min)*</label>
                    <input type="number"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.month_count.$error }"
                           v-model="$v.form.month_count.$model">
                </div>
                <div class="form-group">
                    <label>Allahindlus %*</label>
                    <input type="number"
                           step="any"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.discount.$error }"
                           v-model="$v.form.discount.$model">
                </div>
                <div class="form-group text-center">
                    <button @click="update" class="btn btn-success">
                        Salvesta
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {required} from 'vuelidate/lib/validators';

    export default {
        name: 'EditDiscountModal',
        data() {
            return {
                id: null,
                form: {},
            };
        },
        methods: {
            beforeOpen(event) {
                this.id = event.params.discount.id;
                this.form = Object.assign({}, event.params.discount);
            },
            beforeClose() {
                this.$v.$reset();
            },
            closeModal() {
                this.$modal.hide('edit-discount-modal');
            },
            update() {
                this.$v.$touch();
                if(!this.$v.$invalid) {
                    this.$store.dispatch('UPDATE_DISCOUNT', { id: this.id, data: this.form }).then(() => {
                        this.$toastr.s('Savestatud');
                        this.form = {};
                        this.id = null;
                        this.closeModal();
                    });
                }
            }
        },
        validations: {
            form: {
                month_count: {
                    required
                },
                discount: {
                    required
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v--modal-overlay {
        z-index: 1000;
    }

    .modal-header {
        .fa-times {
            cursor: pointer;
        }
    }
</style>
