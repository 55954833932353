import VueRouter from 'vue-router';

import AuthService from '@services/auth.service';

import Wrapper from './views/Wrapper';
import AuthWrapper from './views/AuthWrapper';
import Login from './views/Auth/Login';
import Register from './views/Auth/Register';
import Home from './views/Home';

import Dashboard from './views/Dashboard';
import Adverts from './views/Adverts';
import Companies from './views/Companies';
import Content from './views/Content';
import CompanyShow from '@views/Companies/views/Show';
import Orders from './views/Orders';

import Unapproved from '@views/Orders/views/Unapproved';
import Approved from '@views/Orders/views/Approved';
import Paid from '@views/Orders/views/Paid';
import Archived from './views/Orders/views/Archived';

import OrderShow from '@views/Orders/views/Show';
import OrderEdit from '@views/Orders/views/Edit';

import settingsRoutes from './views/Settings/routes';

let routes = [
    {
        path: '/',
        component: AuthWrapper,
        children: [
            {
                path: '',
                name: 'home',
                components: {unauthorized: Home}
            },
        ]
    },
    {
        path: '/auth',
        redirect: '/auth/login',
        component: AuthWrapper,
        children: [
            {
                path: 'login',
                name: 'login',
                components: {unauthorized: Login}
            },
            {
                path: 'register',
                name: 'register',
                components: {unauthorized: Register}
            },
        ]
    },
    {
        path: '/auth',
        redirect: 'dashboard',
        component: Wrapper,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                components: {base: Dashboard}
            },
            {
                path: 'adverts',
                name: 'adverts',
                components: {base: Adverts}
            },
            {
                path: 'companies',
                name: 'companies',
                components: {base: Companies}
            },
            {
                path: 'content',
                name: 'content',
                components: {base: Content}
            },
            {
                path: 'companies/:company_id/show',
                name: 'companies.show',
                components: {base: CompanyShow}
            },
            {
                path: 'orders',
                components: {base: Orders},
                children: [
                    {
                        path: '',
                        name: 'orders',
                        components: {orders: Unapproved}
                    },
                    {
                        path: 'approved',
                        name: 'orders.approved',
                        components: {orders: Approved}
                    },
                    {
                        path: 'paid',
                        name: 'orders.paid',
                        components: {orders: Paid}
                    },
                    {
                        path: 'archived',
                        name: 'orders.archived',
                        components: {orders: Archived}
                    },
                ]
            },
            {
                path: 'orders/:order_id/show',
                name: 'orders.show',
                components: {base: OrderShow}
            },
            {
                path: 'orders/:order_id/edit',
                name: 'orders.edit',
                components: {base: OrderEdit}
            },
            ...settingsRoutes
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {
    if (AuthService.isAuthorized) {
        next();
    } else {
        const accessConditions = [
            to.path === '/',
            to.path === '/auth/login',
            to.path.startsWith('/auth/register')
        ];

        if (accessConditions.some(item => item)) {
            next();
        } else {
            if (from.path !== '/auth/login') {
                next({
                    path: '/auth/login'
                });
            }
        }
    }
});

export default router;
