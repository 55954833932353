<template>
    <modal name="create-company-modal" height="auto" :scrollable="true" @before-close="beforeClose">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h3 class="modal-title">Lisa uus</h3>
                <i class="fa fa-times" @click="closeModal()"></i>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Nimi*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.name.$error }"
                           v-model="$v.form.name.$model">
                </div>
                <div class="form-group">
                    <label>Reg. kood</label>
                    <input type="text"
                           class="form-control"
                           v-model="form.reg">
                </div>
                <div class="form-group">
                    <label>Isikukood*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.idcode.$error }"
                           v-model="$v.form.idcode.$model">
                </div>
                <div class="form-group">
                    <label>E-post*</label>
                    <input type="email"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.email.$error }"
                           v-model="$v.form.email.$model">
                </div>
                <div class="form-group">
                    <label>Allahindlus %</label>
                    <input type="number"
                           class="form-control"
                           v-model="form.discount">
                </div>
                <div class="form-group text-center">
                    <button @click="store" class="btn btn-success">
                        Salvesta
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    import {required} from 'vuelidate/lib/validators';

    export default {
        name: 'CreateCompanyModal',
        data() {
            return {
                form: {
                    name: '',
                    reg: '',
                    email: '',
                    idcode: '',
                    discount: 0
                }
            };
        },
        methods: {
            beforeClose() {
                this.$v.$reset();
            },
            closeModal() {
                this.$modal.hide('create-company-modal');
            },
            store() {
                this.$v.$touch();
                if(!this.$v.$invalid) {
                    this.$store.dispatch('CREATE_COMPANY', this.form).then(() => {
                        this.$toastr.s('Savestatud');
                        this.form = {};
                        this.closeModal();
                    });
                }
            }
        },
        validations: {
            form: {
                name: {
                    required
                },
                email: {
                    required
                },
                idcode: {
                    required
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v--modal-overlay {
        z-index: 1000;
    }

    .modal-header {
        .fa-times {
            cursor: pointer;
        }
    }
</style>
