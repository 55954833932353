import Vue from 'vue';
import CompanyService from '@services/company.service';

const state = {
    companies: []
};

const mutations = {
    ['SET_COMPANIES'](state, data) {
        state.companies = data;
    },
    ['REMOVE_COMPANY'](state, companytId) {
        const index = state.companies.findIndex(i => i.id === companytId);
        state.companies.splice(index, 1);
    },
    ['CREATE_COMPANY'](state, data) {
        state.companies.push(data);
    },
    ['UPDATE_COMPANY'](state, data) {
        const index = state.companies.findIndex(i => i.id === data.id);
        Vue.set(state.companies, index, data);
    }
};

const actions = {
    async ['INIT_COMPANIES']({ commit, getters }) {
        if(getters.companies.length) {
            return getters.companies;
        }

        return await CompanyService.companies().then(res => {
            commit('SET_COMPANIES', res);

            return res;
        });
    },
    ['CREATE_COMPANY']({ commit }, data) {
        CompanyService.store(data).then(res => {
            commit('CREATE_COMPANY', res);
        });
    },
    ['UPDATE_COMPANY']({ commit }, { id, data }) {
        CompanyService.update(id, data).then(res => {
            commit('UPDATE_COMPANY', res);
        });
    }
};

const getters = {
    companies(state) {
        return Array.isArray(state.companies) && state.companies.length ? state.companies : [];
    },
    companyById:(state) => (id) =>{
        return state.companies.find(company => company.id === id);
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
