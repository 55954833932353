<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <router-link to="/auth/dashboard" class="navbar-brand">
            Rae Reklaam
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
                aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarColor01">
            <ul class="navbar-nav mr-auto">
                <router-link to="/auth/dashboard" class="nav-item" tag="li" exact>
                    <a class="nav-link">Töölaud</a>
                </router-link>
                <router-link to="/auth/adverts" class="nav-item" tag="li" exact>
                    <a class="nav-link">Reklaamid</a>
                </router-link>
                <router-link to="/auth/companies" class="nav-item" tag="li" exact>
                    <a class="nav-link">Kliendid</a>
                </router-link>
                <router-link to="/auth/orders" class="nav-item" tag="li" exact>
                    <a class="nav-link">Tellimused</a>
                </router-link>
                <router-link to="/auth/settings/discounts" class="nav-item" tag="li" exact>
                    <a class="nav-link">Seaded</a>
                </router-link>
                <router-link to="/auth/content" class="nav-item" tag="li" exact>
                    <a class="nav-link">Sisuhaldus</a>
                </router-link>
            </ul>
            <form class="form-inline">
                <a href="/" class="btn btn-outline-success my-2 my-sm-0 mr-2">Telli</a>
                <button class="btn btn-outline-primary my-2 my-sm-0" type="button" @click="logout">Logi välja</button>
            </form>
        </div>
    </nav>
</template>

<script>
    export default {
        methods: {
            logout() {
                this.$store.dispatch('LOGOUT');
            }
        },
    };
</script>
