import http from './http.service';

class CompanyService {
    constructor() {
        if(CompanyService.instance) {
            return CompanyService.instance;
        }

        CompanyService.instance = this;
    }

    companies() {
        return http.get('/api/companies').then(res => res.data);
    }

    store(data) {
        return http.post('/api/companies/store', data).then(res => res.data);
    }

    show(companytId) {
        return http.get(`/api/companies/${companytId}/show`).then(res => res.data);
    }

    update(companytId, data) {
        return http.put(`/api/companies/${companytId}/update`, data).then(res => res.data);
    }

    delete(companytId) {
        return http.delete(`/api/companies/${companytId}/destroy`).then(res => res.data);
    }
}

export default new CompanyService();
