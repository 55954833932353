import routes from '../../routes';
import http from './http.service';
import {vm} from '@/app';

class AuthService {
    constructor() {
        if (AuthService.instance) {
            return AuthService.instance;
        }

        this._isAuthorized = !!localStorage.getItem('access_token');

        AuthService.instance = this;
    }

    get isAuthorized() {
        return this._isAuthorized;
    }

    login(data) {
        return http.post('/api/login', data).then(res => {
            if (res) {
                http.setToken(res.data.access_token);
                localStorage.setItem('access_token', res.data.access_token);
                this._isAuthorized = true;
                routes.push('/auth/dashboard');
            } else {
                throw new Error(`Something went wrong can't find res for /oauth/token request`, res);
            }
        });
    }

    async logout() {
        if (!this._isAuthorized || !localStorage.getItem('access_token')) {
            return;
        }

        this._isAuthorized = false;

        return http
            .get('/api/logout').then(res => console.log(res.data))
            .catch(_ => console.error('Already logged out'))
            .finally(_ => {
                clearStorage();
                routes.push('/auth/login');
            });
    }

    register(data) {
        return http.post('/api/register', data).then(res => {
            vm.$toastr.s(res.data.message);
            if (res) {
                routes.push('/auth/login');
            } else {
                throw new Error(`Something went wrong can't find res for /oauth/token request`, res);
            }
        });
    }
}

function clearStorage() {
    localStorage.clear();
}

export default new AuthService();
