<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6 offset-md-4 offset-md-3 mt-5">
                <div class="card p-3">
                    <h3 class="card-title text-center">
                        Rae Reklaam
                    </h3>
                    <form @submit.prevent="login">
                        <div class="card-content">
                            <div class="form-group">
                                <label>E-post</label>
                                <input type="email"
                                       class="form-control"
                                       :class="{ 'is-invalid': $v.form.email.$error }"
                                       v-model="$v.form.email.$model">
                            </div>
                            <div class="form-group">
                                <label>Parool</label>
                                <input type="password"
                                       class="form-control"
                                       :class="{ 'is-invalid': $v.form.password.$error }"
                                       v-model="$v.form.password.$model">
                            </div>
                        </div>
                        <button type="submit" class="btn btn-success">Logi sisse</button>
                        <router-link to="/auth/register" class="btn btn-outline-primary" tag="button" exact>
                            Registreeri
                        </router-link>
                        <router-link to="/" class="btn btn-outline-success" tag="button" exact>
                            Tellimine
                        </router-link>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {required, email} from 'vuelidate/lib/validators';

    export default {
        data() {
            return {
                form: {
                    email: '',
                    password: '',
                }
            };
        },
        methods: {
            login() {
                this.$v.$touch();
                if(!this.$v.$invalid) {
                    this.$store.dispatch('LOGIN', this.form);
                }
            }
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                },
                password: {
                    required
                }
            }
        }
    };
</script>
