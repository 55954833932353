<template>
    <modal name="ad-size-preview-modal" height="auto" :scrollable="true" @before-open="beforeOpen">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h3 class="modal-title">Reklaami suuruse eelvaade A4lk</h3>
                <i class="fa fa-times" @click="closeModal()"></i>
            </div>
            <div class="modal-body">
                Orienteeruv reklaami ala kollaselt. {{ name }}
                <div class="row p-4">
                    <div class="offset-4"></div>
                    <div class="ad-page-parent">
                        <div :style="childSize"></div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        name: 'AdSizePreviewModal',
        data() {
            return {
                size: null,
                name: null,
                type: null
            }
        },
        computed: {
            childSize() {
                if (this.size == 1) {
                    return 'background: #ffed4a;left: 0;position: absolute;width: 100%;height: 100%';
                } else if (this.size == '1/2') {
                    return 'background: #ffed4a;left: 0;position: absolute;width: 100%;height: 50%';
                } else if (this.size == '1/4') {
                    return 'background: #ffed4a;left: 0;position: absolute;width: 50%;height: 50%';
                } else if (this.size == '1/6' && this.type == 1) {
                    return 'background: #ffed4a;left: 0;position: absolute;width: 33%;height: 50%';
                } else if (this.size == '1/6' && this.type == 2) {
                    return 'background: #ffed4a;left: 0;position: absolute;width: 69%;height: 18%';
                } else if (this.size == '1/12') {
                    return 'background: #ffed4a;left: 0;position: absolute;width: 33%;height: 25%';
                }
            }
        },
        methods: {
            beforeOpen(event) {
                this.size = event.params.row.size;
                this.name = event.params.row.name;
                this.type = event.params.row.size_type;
            },
            closeModal() {
                this.$modal.hide('ad-size-preview-modal');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .ad-page-parent {
        border: 1px solid black;
        height: 297px;
        width: 180px;
        background: #ebebeb;
        position: relative
    }

    .v--modal-overlay {
        z-index: 1000;
    }

    .modal-header {
        .fa-times {
            cursor: pointer;
        }
    }
</style>
