import AuthService from '@services/auth.service';

const actions = {
    ['REGISTER']({}, data) {
        return AuthService.register(data);
    },
    ['LOGIN']({}, data) {
        return AuthService.login(data);
    },
    ['LOGOUT']({}) {
        AuthService.logout().then(() => {
            location.reload();
        });
    }
};

export default {
    actions
};
