<template>
    <div class="container-fluid">
        <Header name="Ettevõtted" addNew searchable @create="create" v-model="term"/>
        <table class="table table-hover bg-white mt-3">
            <thead>
            <tr>
                <th>#</th>
                <th>Nimi</th>
                <th>Reg. kood</th>
                <th>Isikukood</th>
                <th>E-post</th>
                <th>Allahindlus %</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(company, i) in filteredCompanies" :key="company.id">
                <td>{{ i + 1}}</td>
                <td>
                    <router-link :to="{name:'companies.show', params:{company_id:company.id}}">
                        {{ company.name }}
                    </router-link>
                </td>
                <td>{{ company.reg }}</td>
                <td>{{ company.idcode }}</td>
                <td>
                    <a :href="`mailto:${company.email}`">{{ company.email }}</a>
                </td>
                <td>{{ company.discount }}</td>
                <td>
                    <div class="pull-right">
                        <button type="button" class="btn btn-outline-primary btn-sm" @click="edit(company)">Muuda</button>
                        <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteCompany(company.id)">Kustuta</button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <CreateModal />
        <EditModal />
    </div>
</template>

<script>
    import Header from '@components/layout/Header';
    import CreateModal from './modals/CreateCompanyModal';
    import EditModal from './modals/EditCompanyModal';
    import CompanyService from '@services/company.service';
    import {mapGetters} from 'vuex';

    export default {
        data() {
            return {
                term: ''
            }
        },
        computed: {
            ...mapGetters(['companies']),
            filteredCompanies() {
                return this.companies.filter(company => {
                    if (!company.reg) {
                        return false;
                    }
                    return (
                        company.name.toLowerCase().match(this.term.toLowerCase()) ||
                        company.reg.match(this.term) ||
                        company.email.toLowerCase().match(this.term.toLowerCase())
                    );
                });
            }
        },
        methods: {
            deleteCompany(companyId) {
                if (!window.confirm('Kustutame?')) {
                    return false;
                }
                CompanyService.delete(companyId).then(res => {
                    this.$store.commit('REMOVE_COMPANY', companyId);
                    this.$toastr.s(res.message);
                });
            },
            create() {
                this.$modal.show('create-company-modal');
            },
            edit(company) {
                this.$modal.show('edit-company-modal', { company });
            }
        },
        components: {
            Header,
            CreateModal,
            EditModal
        },
        created() {
            this.$store.dispatch('INIT_COMPANIES');
        }
    };
</script>
