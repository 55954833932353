<template>
    <div class="tab-pane fade show active">
        <table class="table table-hover bg-white mt-3">
            <thead>
            <tr>
                <th>Tellimuse nr</th>
                <th>Tellija</th>
                <th>Kuupäev</th>
                <th>Kuu(d)</th>
                <th>Pindade arv</th>
                <th>Arhiveeritud</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(order, i) in archivedOrders" :key="order.id">
                <td>{{ order.id }}</td>
                <td>
                    <router-link :to="{name:'orders.show', params:{order_id:order.id}}">
                        {{ order.company.name }}
                    </router-link>
                </td>
                <td>
                    {{ order.created_at | formatDate }}
                    <template v-if="order.approved_at">
                        | kinnitatud: {{ order.approved_at | formatDate }}
                    </template>
                </td>
                <td>
                    <template v-if="order.rows.length == 1">
                        <p v-for="month in order.rows[0].months" class="m-0">
                            {{ month.month ? month.month : month }}
                        </p>
                    </template>
                    <template v-else>
                        {{ order.rows.length }}
                    </template>
                </td>
                <td>{{ order.rows.length }}</td>
                <td>{{ order.archived_at | formatDate }}</td>
                <td>
                    <div class="pull-right">
                        <button type="button" class="btn btn-outline-primary btn-sm" @click="archiveOrder(order.id, 'Taastame arhiivist?')">
                            Taasta
                        </button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        computed: {
            ...mapGetters(['archivedOrders'])
        },
        methods: {
            archiveOrder(orderId, msg) {
                if (!window.confirm(msg)) {
                    return false;
                }
                this.$store.dispatch('ARCHIVE_ORDER', orderId).then(res => {
                    this.$store.commit('UPDATE_ORDER', res.order)
                    this.$toastr.s(res.message);
                });
            }
        },
        created() {
            this.$store.dispatch('INIT_ARCHIVED_ORDERS');
        }
    };
</script>
