import axios from 'axios';
import authService from './auth.service';
import {vm} from '@/app';

class HttpService {
    constructor() {
        this.generateCancelToken();

        if (HttpService.instance) {
            return HttpService.instance;
        }

        axios.interceptors.response.use(
            response => {
                if (response && [201, 200].includes(response.status)) {
                    return response;
                }
                return Promise.reject(response);
            },
            error => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401: {
                            this.cancelRequests(error.response);
                            authService.logout();
                            break;
                        }
                        case 402: {
                            console.log('Payment required')
                            break;
                        }
                        case 413: {
                            console.log('Attachment is too large')
                            break;
                        }
                        case 429: {
                            console.log('Too many requests')

                            this.cancelRequests(error.response);
                            authService.logout();
                            break;
                        }
                        case 404:
                        case 422: {
                            break;
                        }
                        default: {
                            break;
                        }
                    }

                    if (error.response.data) {
                        if (error.response.data.errors) {
                            Object.keys(error.response.data.errors).forEach(key => {
                                console.log(error.response.data.errors[key])
                            });
                        } else if (error.response.data.message) {
                            console.log(error.response.data.message)
                            vm.$toastr.e(error.response.data.message);
                        } else {
                            console.log('Unknown error')
                        }
                    } else if (error.response.message) {
                        console.log(error.message)
                    } else {
                        console.log(error)
                    }
                    return Promise.reject(error);
                } else if (error && error.response && error.response.message === 'Network Error') {
                    console.log(error.message)
                    return Promise.reject(error);
                } else {
                    return Promise.reject(error);
                }
            }
        );

        this.setToken();

        HttpService.instance = this;
    }

    get cancelSource() {
        return this._cancelSource;
    }

    get cancelToken() {
        return this.cancelSource.token;
    }

    generateCancelToken() {
        const CancelToken = axios.CancelToken;
        this._cancelSource = CancelToken.source();
    }

    cancelRequests(message) {
        this.cancelSource.cancel(message);
        this.generateCancelToken();
    }

    setToken(value) {
        const token = value || localStorage.getItem('access_token');

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    get(url, options) {
        return axios.get(url, options);
    }

    post(url, body, options) {
        return axios.post(url, body, options);
    }

    put(url, body, options) {
        return axios.put(url, body, options);
    }

    delete(url, options) {
        return axios.delete(url, options);
    }

    download(url) {
        return axios.get(url, {responseType: 'blob'});
    }
}

export default new HttpService();
