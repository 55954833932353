<template>
    <div class="tab-pane fade show active">
        <table class="table table-hover bg-white mt-3">
            <thead>
            <tr>
                <th>#</th>
                <th>Kuude kogus (min)</th>
                <th>Allahindlus %</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(discount, i) in discounts" :key="discount.id">
                <td>{{ i + 1}}</td>
                <td>{{ discount.month_count }}</td>
                <td>{{ discount.discount }}</td>
                <td>
                    <div class="pull-right">
                        <button type="button" class="btn btn-outline-primary btn-sm" @click="edit(discount)">Muuda</button>
                        <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteDiscount(discount.id)">Kustuta</button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <CreateModal />
        <EditModal />
    </div>
</template>

<script>
    import CreateModal from './modals/CreateDiscountModal';
    import EditModal from './modals/EditDiscountModal';
    import DiscountService from '@services/discount.service';
    import {mapGetters} from 'vuex';

    export default {
        computed: {
            ...mapGetters(['discounts'])
        },
        methods: {
            deleteDiscount(discountId) {
                if (!window.confirm('Kustutame?')) {
                    return false;
                }
                DiscountService.delete(discountId).then(res => {
                    this.$store.commit('REMOVE_DISCOUNT', discountId);
                    this.$toastr.s(res.message);
                });
            },
            edit(discount) {
                this.$modal.show('edit-discount-modal', { discount });
            }
        },
        components: {
            CreateModal,
            EditModal
        },
        created() {
            this.$store.dispatch('INIT_DISCOUNTS');
        },
        beforeRouteEnter(to, from, next) {
            if (to.path === '/auth/settings/discounts') {
                next(vm => {
                    vm.$parent.setPage({addNew: true, pageName: 'Allahindlused'})
                });
            } else {
                next(vm => {
                    vm.$parent.setPage({addNew: false, pageName: 'Seaded'})
                });
            }
        }
    };
</script>
