<template>
    <div class="mt-3">
        <div class="row">
            <div class="col">
                <h3>{{ name }}</h3>
            </div>
            <div class="col-2" v-if="searchable">
                <input type="text" class="form-control" placeholder="Otsi..." v-model="val">
            </div>
            <div class="col-1" v-if="addNew">
                <button tag="button" class="btn btn-success pull-right" @click="create">
                    Lisa uus
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        props: {
            name: String,
            value: [String, Number],
            addNew: Boolean,
            searchable: Boolean,
        },
        computed: {
            val: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        methods: {
            create() {
                this.$emit('create');
            }
        }
    }
</script>
