import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@store/auth';
import advert from '@store/advert';
import discount from '@store/discount';
import company from '@store/company';
import order from '@store/order';
import orders from '@store/orders';
import content from '@store/content';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        advert,
        discount,
        company,
        order,
        orders,
        content
    }
});
