import Vue from 'vue';
import AdvertService from '@services/advert.service';

const state = {
    adverts: []
};

const mutations = {
    ['SET_ADVERTS'](state, data) {
        state.adverts = data;
    },
    ['REMOVE_ADVERT'](state, advertId) {
        const index = state.adverts.findIndex(i => i.id === advertId);
        state.adverts.splice(index, 1);
    },
    ['CREATE_ADVERT'](state, data) {
        state.adverts.push(data);
    },
    ['UPDATE_ADVERT'](state, data) {
        const index = state.adverts.findIndex(i => i.id === data.id);
        Vue.set(state.adverts, index, data);
    }
};

const actions = {
    async ['INIT_ADVERTS']({ commit, getters }) {
        if(getters.adverts.length) {
            return getters.adverts;
        }

        return await AdvertService.adverts().then(res => {
            commit('SET_ADVERTS', res);

            return res;
        });
    },
    ['CREATE_ADVERT']({ commit }, data) {
        AdvertService.store(data).then(res => {
            commit('CREATE_ADVERT', res);
        });
    },
    ['UPDATE_ADVERT']({ commit }, { id, data }) {
        AdvertService.update(id, data).then(res => {
            commit('UPDATE_ADVERT', res);
        });
    }
};

const getters = {
    adverts(state) {
        return Array.isArray(state.adverts) && state.adverts.length ? state.adverts : [];
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
