import Vue from 'vue';
import http from '@services/http.service';

const actions = {
    GET_PUBLIC_CONTENT({ commit }) {
        return http.get('/api/public/content').then(res => res.data);
    },
    GET_CONTENT({ commit }) {
        return http.get('/api/content').then(res => res.data);
    },
    UPDATE_CONTENT({ commit }, form) {
        return http.put('/api/content/update', form).then(res => res.data);
    }
};

export default {
    actions
};
