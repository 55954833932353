<template>
    <div class="tab-pane fade show active">
        <table class="table table-hover bg-white mt-3">
            <thead>
            <tr>
                <th>#</th>
                <th>Seade</th>
                <th>Väärtus</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(setting, i) in settings" :key="setting.id">
                <td>{{ i + 1 }}</td>
                <td>{{ setting.type === 'auto_lock_month_date' ? 'Tellimiue automaatse lukustamise kuupäev' : 'Other' }}</td>
                <td>{{ setting.value }}</td>
                <td>
                    <div class="pull-right">
                        <button type="button" class="btn btn-outline-primary btn-sm" @click="edit(setting)">Muuda</button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
<!--        <button @click="migrate">Migrate</button>-->
<!--        <button @click="rollback">Rollback</button>-->
        <EditModal @updateSettings="updateSettings"/>
    </div>
</template>

<script>
    import SettingsService from '@services/settings.service';
    import EditModal from './modals/EditSettingsModal';
    import Vue from 'vue';

    export default {
        data() {
            return {
                settings: []
            }
        },
        methods: {
            migrate() {
                SettingsService.migrate().then(res => {
                    this.$toastr.s('DONE');
                });
            },
            rollback() {
                SettingsService.rollback().then(res => {
                    this.$toastr.s('DONE');
                });
            },
            getSettings() {
                SettingsService.getSettings().then(res => {
                    this.settings = res;
                });
            },
            edit(setting) {
                this.$modal.show('edit-settings-modal', { setting });
            },
            updateSettings(setting) {
                const index = this.settings.findIndex(set => set.id === setting.id);
                Vue.set(this.settings, index, setting);
            }
        },
        components: {
            EditModal
        },
        created() {
            this.getSettings();
        },
        beforeRouteEnter(to, from, next) {
            if (to.path === '/auth/settings/discounts') {
                next(vm => {
                    vm.$parent.setPage({addNew: true, pageName: 'Allahindlused'})
                });
            } else {
                next(vm => {
                    vm.$parent.setPage({addNew: false, pageName: 'Seaded'})
                });
            }
        }
    };
</script>
