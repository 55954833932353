<template>
    <modal name="edit-advert-modal" height="auto" :scrollable="true" @before-open="beforeOpen"
           @before-close="beforeClose">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h4 class="modal-title">Muuda</h4>
                <i class="fa fa-times" @click="closeModal()"></i>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Nimi*</label>
                    <input type="text"
                           class="form-control"
                           :class="{ 'is-invalid': $v.form.name.$error }"
                           v-model="$v.form.name.$model">
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Suurus*</label>
                            <input type="text"
                                   class="form-control"
                                   :class="{ 'is-invalid': $v.form.size.$error }"
                                   v-model="$v.form.size.$model">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Hind €/kuu*</label>
                            <input type="number"
                                   step="any"
                                   class="form-control"
                                   :class="{ 'is-invalid': $v.form.price.$error }"
                                   v-model="$v.form.price.$model">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="files_allowed">Failid lubatud</label>
                            <div class="custom-control custom-checkbox pt-0">
                                <input type="checkbox" class="custom-control-input pt-0" id="files_allowed"
                                       v-model="form.files_allowed">
                                <label class="custom-control-label" for="files_allowed"></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Uus hind €/kuu</label>
                            <input type="number"
                                   step="any"
                                   class="form-control"
                                   v-model="form.new_price">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Uue hinna algus</label>
                            <input type="date"
                                   class="form-control"
                                   v-model="form.new_price_at">
                        </div>
                    </div>
                </div>
                <div class="form-group text-center">
                    <button @click="update" class="btn btn-success">
                        Salvesta
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import {filters} from '@/filters.js';

export default {
    name: 'EditAdvertModal',
    data() {
        return {
            id: null,
            form: {},
        };
    },
    methods: {
        beforeOpen(event) {
            this.id = event.params.advert.id;
            this.form = Object.assign({}, event.params.advert);
            this.form.price = this.form.price / 100;
            if (this.form.new_price_at) {
                this.form.new_price_at = filters.formatDateInput(this.form.new_price_at)
            }
            if (this.form.new_price) {
                this.form.new_price = this.form.new_price / 100;
            }
        },
        beforeClose() {
            this.$v.$reset();
        },
        closeModal() {
            this.$modal.hide('edit-advert-modal');
        },
        update() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$store.dispatch('UPDATE_ADVERT', {id: this.id, data: this.form}).then(() => {
                    this.$toastr.s('Savestatud');
                    this.form = {};
                    this.id = null;
                    this.closeModal();
                });
            }
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            size: {
                required
            },
            price: {
                required
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.v--modal-overlay {
    z-index: 1000;
}

.modal-header {
    .fa-times {
        cursor: pointer;
    }
}
</style>
